import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/travels/Layout";
import Tickets from "./components/travels/Tickets";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Tickets />}></Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;

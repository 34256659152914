import React from "react";

const Header = () => {
    return (
        <div
            className="row py-3"
        >
            <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-start">
                <a href="/">
                    <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" style={{ width: 250 }} />
                </a>
            </div>
            <div className="col-sm-12 col-md-6  d-flex justify-content-center justify-content-md-end align-items-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary">Find Ticket</button>
                    <button type="button" className="btn btn-primary" style={{ marginLeft: 1 }}>Sing In</button>
                </div>
            </div>
        </div >
    );
};
export default Header;

import { useEffect, useRef, useState } from "react"
import useOutsideClick from "../hooks/useOutsideClick"

const Destinations = ({ placeholder, classStyle, where, travelFromTo, setTravelFromTo }) => {
    const [showList, setShowList] = useState(false)
    const [airports, setAiports] = useState([])
    const [selectedAirport, setSelectedAirport] = useState({})
    const [selectedAirportCity, setSelectedAirportCity] = useState("")

    const inputRef = useRef(null);

    useOutsideClick(inputRef, () => {
        if (showList) setShowList(false);
    });

    const airporData = [
        { city: "Dhaka", country: "Bangladesh", code: "DAC", airport: "Hazrat Shahjalal Airport", continent: "Asia" },
        { city: "Chittagong", country: "Bangladesh", code: "CGP", airport: "Chittagong Airpor", continent: "Asia" },
        { city: "Sylhet", country: "Bangladesh", code: "ZYL", airport: "Osman Gani Airport", continent: "Asia" },
    ]


    const handleAirportList = (e) => {
        const val = e?.target?.value?.split(placeholder + " ")[1]
        if (val) {
            setSelectedAirportCity(val.trim())
            const fAirports = airporData?.filter(d => d?.code?.toLowerCase().includes(val?.trim().toLowerCase()) || d?.city?.toLowerCase().includes(val?.trim().toLowerCase()) || d?.airport?.toLowerCase().includes(val?.trim().toLowerCase()) || d?.country?.toLowerCase().includes(val?.trim().toLowerCase()))
            setAiports(fAirports)
        } else {
            setSelectedAirportCity("")
        }

    }

    const handleAirportSelect = (airport) => {
        setSelectedAirport(airport)
        setShowList(false)
    }


    useEffect(() => {
        setAiports(airporData)
    }, [])


    useEffect(() => {
        console.log(selectedAirport)
        selectedAirport?.code && setTravelFromTo({ ...selectedAirport })
    }, [selectedAirport])

    useEffect(() => {
        console.log(where + " has changed", travelFromTo[where])
        travelFromTo[where]?.code && setSelectedAirportCity(travelFromTo[where]?.city)
    }, [travelFromTo])


    const renderAirporList = () => {
        return airports?.length > 0 ? airports?.map((a, ix) => {
            return <button key={`airport_${a?.code}`} className="btn btn-outline-primary border-0 d-flex align-items-center w-100 text-dark text-hover-white" onClick={() => handleAirportSelect(a)}>
                <span className="d-inline-block fw-semibold text-start" style={{ width: 45 }}>{a?.code}</span>
                <div className="border-start border-1 border-secondary border-opacity-25 d-flex flex-column ps-2">
                    <span className="text-start font-size-11 ms-1">{a?.city}</span>
                    <span className="font-size-8 text-start ms-1">{a?.airport}</span>
                </div>
            </button>

        }
        ) : <button className="btn btn-light w-100 text-start" disabled>Not Found</button>
    }
    return <div className={classStyle + " position-relative flex-fill"}>
        <input className={"form-control border-dark p-3 ps-4"} placeholder={placeholder} onFocus={() => setShowList(true)} onClick={() => setShowList(true)} onChange={e => handleAirportList(e)} value={(placeholder + "  " + selectedAirportCity) || ""}></input>
        {showList && <div ref={inputRef} className="bg-white border rounded p-1 position-absolute overflow-scroll z-3" style={{ width: 275, maxHeight: 175 }}>
            {renderAirporList()}
        </div>}
    </div>


}

export default Destinations
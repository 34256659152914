import { IoPersonOutline, IoSearch } from "react-icons/io5";
import { LuArrowLeftRight, LuArrowRightLeft } from "react-icons/lu";
import { useEffect, useState } from "react";
import Destinations from "./Destinations";
import moment from "moment";
import MyDateRangePicker from "../common/MyDateRangePicker";


const AirSearchingPanel = () => {
    const [trip, setTrip] = useState({ id: "one", name: "One Way" })
    const [tripClass, setTripClass] = useState({ id: "economy", name: "Economy" })
    const [travelFromTo, setTravelFromTo] = useState({
        from: "",
        to: "",
        order: 0
    })
    const [travelDate, setTravelDate] = useState({
        startDate: "",
        returnDate: ""
    })
    const [traveller, setTraveller] = useState({
        adult: 1,
        children: 0,
        infant: 0,
        total: 1
    })

    const handleTravelFromTo = () => {
        console.log(travelFromTo)
        const from = travelFromTo?.from;
        const to = travelFromTo?.to;
        const changedTravelDirection = { from: to, to: from, order: travelFromTo?.order === 0 ? 1 : 0 }
        setTravelFromTo(changedTravelDirection)
    }

    useEffect(() => {
        console.log("travelFromTo", travelFromTo)
    }, [travelFromTo])

    useEffect(() => {
        setTraveller({ ...traveller, total: parseInt(traveller?.adult) + parseInt(traveller?.children) + parseInt(traveller?.infant) })
    }, [traveller?.adult, traveller?.children, traveller?.infant])


    const handleUpdateTravelFrom = (v) => {
        setTravelFromTo({ ...travelFromTo, from: v })
    }

    const handleUpdateTravelTo = (v) => {
        setTravelFromTo({ ...travelFromTo, to: v })
    }

    const renderTrip = () => {
        const trips = [{ id: "one", name: "One Way" }, { id: "round", name: "Round Trip" }, { id: "multi", name: "Multi City" }]
        return trips.map(t => <button key={"trip_" + t?.id} className={(trip?.id === t?.id ? "btn-dark" : "btn-outline-dark") + " btn btn-sm border-0 me-2"} onClick={() => setTrip(t)}>{t?.name}</button >)
    }

    const renderTripClass = () => {
        const classes = [{ id: "economy", name: "Economy" }, { id: "business", name: "Business" }]
        return classes.map(c => <button key={"class_" + c?.id} className={(tripClass?.id === c?.id ? "btn-dark" : "btn-outline-dark") + " btn btn-sm border-0 me-2"} onClick={() => setTripClass(c)}>{c?.name}</button >)
    }

    return (
        <div className="bg-light py-3 pb-5 rounded position-relative shadow-sm">
            <div className="row m-0">
                <div className="col-sm-12 d-flex">
                    <div className="d-flex">
                        {renderTrip()}
                    </div>
                </div>
            </div>
            <div className="row gx-2 m-0 mx-2 mt-2">
                <div className="col-sm-12 col-lg-6 d-flex position-relative">
                    <Destinations placeholder="From" where="from" classStyle="me-1" travelFromTo={travelFromTo} setTravelFromTo={handleUpdateTravelFrom} />
                    <Destinations placeholder="To" where="to" classStyle="ms-1" travelFromTo={travelFromTo} setTravelFromTo={handleUpdateTravelTo} />
                    <button className="btn btn-sm border-secondary bg-white shadow-sm rounded-circle position-absolute top-50 start-50 translate-middle d-flex align-items-center py-2" onClick={handleTravelFromTo}>{travelFromTo?.order === 0 ? <LuArrowLeftRight className="fs-6" /> : <LuArrowRightLeft className="fs-6" />}</button>
                </div>
                <div className="col-sm-12 col-lg-4 mt-2 mt-lg-0">
                    <MyDateRangePicker setTravelDate={setTravelDate} />
                </div>
                <div className="col-sm-12 col-lg-2 d-flex mt-2 mt-lg-0">
                    <div className="btn-group w-100" role="group">
                        <button type="button" className="btn btn-outline-dark bg-white text-dark dropdown-toggle rounded d-flex align-items-center py-3" data-bs-toggle="dropdown" aria-expanded="false">
                            <IoPersonOutline className="fs-4 d-block d-md-none" /><span className="mx-2 text-start w-100">{traveller?.total} {traveller?.total > 1 ? "Travellers" : "Traveller"}</span>
                        </button>
                        <ul className="dropdown-menu" style={{ width: 250 }}>
                            <div className="dropdown-item dropdown-item-primary d-flex justify-content-between align-items-center py-2" >
                                <span className="d-inline-block me-2">Adult</span>
                                <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveller({ ...traveller, adult: e.target.value })}>
                                    {Array(10).fill(0).map((_, i) => i + 1).map(i => <option key={"adult_" + i} value={i}>{i}</option>)}
                                </select>
                            </div>
                            <div className="dropdown-item dropdown-item-primary d-flex justify-content-between align-items-center py-2">
                                <div>
                                    <span className="d-block me-2">Children</span>
                                    <span className="d-block me-2 font-size-9">Aged 2 - 11</span>
                                </div>
                                <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveller({ ...traveller, children: e.target.value })}>
                                    {Array(10).fill(0).map((_, i) => i).map(i => <option key={"children_" + i} value={i}>{i}</option>)}
                                </select>
                            </div>
                            <div className="dropdown-item dropdown-item-primary d-flex justify-content-between align-items-center py-2">
                                <div>
                                    <span className="d-block me-2">Infant</span>
                                </div>
                                <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveller({ ...traveller, infant: e.target.value })}>
                                    {Array(10).fill(0).map((_, i) => i).map(i => <option key={"infant_" + i} value={i}>{i}</option>)}
                                </select>
                            </div>
                            <div className="mt-2 border-top px-3 py-1">
                                <span className="d-block font-size-10">Class</span>
                                <div className="d-flex flex-wrap mt-2">
                                    {renderTripClass()}
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="position-absolute top-100 start-50 translate-middle">
                    <button className="btn btn-primary rounded-pill py-2 px-5"><IoSearch className="fs-4 me-2" />Search</button>
                </div>
            </div>
        </div>
    );
};
export default AirSearchingPanel;

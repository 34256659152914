import { Tab } from "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useState } from "react";
import { Tabs } from "react-bootstrap";
import { IoAirplane, IoBus } from "react-icons/io5";
import AirSearchingPanel from "./AirSearchingPanel";
import { BsTrainFront } from "react-icons/bs";

const TicketFindingPanel = () => {
    const [key, setKey] = useState('air');

    return (

        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="air" className="py-3" title={<span className="fs-6  d-flex align-items-center"><IoAirplane className="fs-4 me-2" /> Air</span>}>
                <AirSearchingPanel />
            </Tab>
            <Tab eventKey="bus" title={<span className="fs-6  d-flex align-items-center"><IoBus className="fs-4 me-2" /> Bus</span>}>
                Coming Soon
            </Tab>
            <Tab eventKey="train" title={<span className="fs-6 d-flex align-items-center"><BsTrainFront className="fs-4 me-2" /> Train</span>}>
                Coming Soon
            </Tab>
        </Tabs>
    );
};
export default TicketFindingPanel;

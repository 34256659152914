import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Override.css';

const MyDateRangePicker = ({ setTravelDate }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        setTravelDate({ startDate: startDate, returnDate: endDate })
    }, [startDate, endDate])

    return (
        <div>
            <DateRangePicker
                startDate={startDate} // Start date of the range
                startDatePlaceholderText={"Journey Date"}
                endDatePlaceholderText={"Return Date"}
                startDateId="start-date" // Unique ID for the start date
                endDate={endDate} // End date of the range
                endDateId="end-date" // Unique ID for the end date
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }} // Callback for date change
                focusedInput={focusedInput} // Which input is focused
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // Callback for focus change
                displayFormat="YYYY-MM-DD" // Format displayed in the input
                isOutsideRange={() => false} // Allow all dates (adjust as needed)
                minimumNights={0} // Minimum nights for date range (set to 0 to allow same-day range)
                showClearDates={true} // Option to clear dates
                numberOfMonths={2} // Number of months shown in the calendar
                reopenPickerOnClearDates={true}
            />
        </div>
    );
};

export default MyDateRangePicker;

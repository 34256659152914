import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
    return (
        <div className="" style={{ maxWidth: 1440 }}>
            <div className="row">
                <div className="col-sm-12 col-md-10 mx-auto">
                    <Header />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-10 mx-auto">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default Layout;